._index-module__top__container__3mFdh {
  width: 100%;
  color: #f5f5f5;
  position: relative;
}

._index-module__sign__hYxHF {
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  top: 20%;
  right: -40px;
  z-index: 9;
  transform: rotate(270deg);
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: white;
  text-transform: uppercase;
}
@media (max-width: 700px) {
  ._index-module__sign__hYxHF {
    top: 30%;
    right: -50px;
  }
}

._index-module__slider__ce6uO {
  height: 800px;
  display: flex;
  padding: 0;
  flex-flow: column wrap;
  justify-content: center;
  margin: 0;
  max-width: 100%;
  text-transform: uppercase;
  position: relative;
  font-family: "Druk Wide Medium";
  overflow: hidden;
}
@media (max-width: 800px) {
  ._index-module__slider__ce6uO {
    height: 600px;
  }
}
._index-module__slider__ce6uO._index-module__slider__title__1awsp {
  max-width: 90%;
  font-size: 24pt;
  font-weight: normal;
  text-transform: uppercase;
  background-color: #003a78;
}

._index-module__slider__text__2dksi {
  max-width: 1300px;
  margin: 1rem auto;
  z-index: 10;
  text-transform: uppercase;
}
._index-module__slider__text__2dksi > p {
  margin: 1rem auto;
  line-height: 1.5rem;
  text-align: left;
  max-width: 1180px;
  padding: 0 4rem;
}
@media (max-width: 900px) {
  ._index-module__slider__text__2dksi > p {
    font-size: 12pt;
  }
}
@media (max-width: 700px) {
  ._index-module__slider__text__2dksi > p {
    font-size: 10pt;
    padding: 0 2rem;
  }
}

._index-module__slider__image__32p8j {
  position: absolute;
  top: 0;
  margin: 0;
  padding: 0;
  text-align: center;
  display: flex;
  align-items: center;
  box-pack: center;
  justify-content: center;
  overflow: hidden;
}
._index-module__slider__image__32p8j > img {
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  margin: auto auto;
  opacity: 1;
  transition: opacity 350ms ease-out;
}

._index-module__slider__text__title__1wrLl {
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  max-width: 1300px;
  flex-direction: column;
  color: #f5f5f5;
  transition: all 1.5s;
  transition-timing-function: ease-in-out;
}
._index-module__slider__text__title__1wrLl > h1 {
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  background-color: #003a78;
  line-height: normal;
  padding: 10px 1rem 10px 4rem;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0;
  font-size: 32pt;
  white-space: nowrap;
}
@media (max-width: 900px) {
  ._index-module__slider__text__title__1wrLl > h1 {
    font-size: 25px;
  }
}
@media (max-width: 700px) {
  ._index-module__slider__text__title__1wrLl > h1 {
    font-size: 22px;
    padding: 10px 1rem 10px 2rem;
  }
}
@media (max-width: 600px) {
  ._index-module__slider__text__title__1wrLl > h1 {
    font-size: 20px;
    padding: 10px 1rem 10px 2rem;
  }
}
@media (max-width: 600px) {
  ._index-module__slider__text__title__1wrLl > h1 {
    font-size: 16px;
    padding: 10px 1rem 10px 2rem;
  }
}

._index-module__slider__text__title__projects__KYxTi {
  position: relative;
  justify-content: flex-end;
  color: #f5f5f5;
  max-width: 1300px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}
@media (max-width: 600px) {
  ._index-module__slider__text__title__projects__KYxTi {
    justify-content: center;
  }
}
._index-module__slider__text__title__projects__KYxTi > h1 {
  display: flex;
  top: 50;
  background-color: #003a78;
  line-height: normal;
  padding: 10px 1rem 0 4rem;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0;
  font-size: 16pt;
}
@media (max-width: 900px) {
  ._index-module__slider__text__title__projects__KYxTi > h1 {
    font-size: 25px;
  }
}
@media (max-width: 700px) {
  ._index-module__slider__text__title__projects__KYxTi > h1 {
    font-size: 22px;
    padding: 10px 1rem 10px 2rem;
  }
}
@media (max-width: 600px) {
  ._index-module__slider__text__title__projects__KYxTi > h1 {
    font-size: 20px;
    padding: 10px 1rem 10px 2rem;
  }
}
@media (max-width: 600px) {
  ._index-module__slider__text__title__projects__KYxTi > h1 {
    font-size: 16px;
    padding: 10px 1rem 10px 2rem;
  }
}
._index-module__slider__text__title__projects__KYxTi > h1:nth-child(2) {
  padding: 0 1rem 10px 4rem;
  text-transform: uppercase;
  font-size: 16pt;
}
@media (min-width: 768px) {
  ._index-module__slider__text__title__projects__KYxTi > h1:nth-child(2) {
    font-size: 30pt;
  }
}
._index-module__slider__text__title__projects__KYxTi > p {
  padding: 10px 1rem 10px 4rem;
  background-color: #003a78;
  margin: 0;
}

._index-module__text__container__qD6b0 {
  color: #fff;
  padding: 3rem 0;
  background-color: #003a78;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  text-align: left;
  margin: auto;
  font-size: 14pt;
}

._index-module__slider__bottom__29-Bv {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0px 15px;
  align-items: center;
  background-color: transparent;
}

._index-module__slider__nav__3KvaY {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  align-items: start;
  flex-direction: column;
  top: 0;
}
._TextButtonLineSkin-module__ctaBox__3BsWG {
  display: flex;
  width: 100%;
  margin: 20px auto;
  gap: 60px;
  align-items: center;
  justify-content: space-between;
  background-color: #003a78;
  padding: 20px 40px;
}
@media (max-width: 500px) {
  ._TextButtonLineSkin-module__ctaBox__3BsWG {
    flex-direction: column;
  }
}
._TextButtonLineSkin-module__ctaBox__3BsWG > p {
  color: white;
  width: 100%;
  border-bottom: 1px solid white;
  padding-bottom: 5px;
  text-transform: uppercase;
}

._TextButtonLineSkin-module__buttonWhite__1Hg5x {
  outline: none;
  color: #003a78 !important;
  background-color: white !important;
  border-radius: 0 !important;
  padding: 8px 16px;
  border: none;
  width: 200px;
  cursor: pointer;
  font-family: "Druk Wide Medium";
  font-size: 12px !important;
  text-transform: uppercase;
}
._TitleWithFixedImage-module__mainContainer__1kRx0 {
  position: relative;
  width: 100%;
  overflow: hidden;
}
._TitleWithFixedImage-module__mainContainer__1kRx0 ._TitleWithFixedImage-module__sign__1_Y6Z {
  position: absolute;
  right: -40px;
  z-index: 9;
  transform: rotate(270deg);
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: white;
  text-transform: uppercase;
}
@media (max-width: 981px) {
  ._TitleWithFixedImage-module__mainContainer__1kRx0 ._TitleWithFixedImage-module__sign__1_Y6Z {
    right: -80px;
  }
}
@media (max-width: 981px) {
  ._TitleWithFixedImage-module__mainContainer__1kRx0 {
    height: 100%;
  }
}
._TitleWithFixedImage-module__mainContainer__1kRx0 img {
  height: 800px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 981px) {
  ._TitleWithFixedImage-module__mainContainer__1kRx0 img {
    height: 600px;
  }
}
._TitleWithFixedImage-module__mainContainer__1kRx0 ._TitleWithFixedImage-module__titleContainer__1bO9m {
  position: absolute;
  left: 0;
  transform: translate(0, -50%);
  padding: 10px 1rem 10px 4rem;
  background-color: #003a78;
  transition: all 1.5s;
  transition-timing-function: ease-in-out;
}
@media (max-width: 981px) {
  ._TitleWithFixedImage-module__mainContainer__1kRx0 ._TitleWithFixedImage-module__titleContainer__1bO9m {
    top: 40%;
  }
}
._TitleWithFixedImage-module__mainContainer__1kRx0 ._TitleWithFixedImage-module__titleContainer__1bO9m ._TitleWithFixedImage-module__title__AOYPo {
  font-size: 32pt;
  text-transform: uppercase;
  color: #f5f5f5;
  margin: 0;
  font-family: "Druk Wide Medium";
  letter-spacing: 3px;
}
@media (max-width: 900px) {
  ._TitleWithFixedImage-module__mainContainer__1kRx0 ._TitleWithFixedImage-module__titleContainer__1bO9m ._TitleWithFixedImage-module__title__AOYPo {
    font-size: 28pt;
  }
}
@media (max-width: 650px) {
  ._TitleWithFixedImage-module__mainContainer__1kRx0 ._TitleWithFixedImage-module__titleContainer__1bO9m ._TitleWithFixedImage-module__title__AOYPo {
    font-size: 20pt;
  }
}
@media (max-width: 500px) {
  ._TitleWithFixedImage-module__mainContainer__1kRx0 ._TitleWithFixedImage-module__titleContainer__1bO9m ._TitleWithFixedImage-module__title__AOYPo {
    font-size: 12pt;
  }
}
._TitleWithFixedImage-module__mainContainer__1kRx0 ._TitleWithFixedImage-module__innerHtmlContainer__1qMli {
  position: absolute;
  bottom: 4px;
  justify-content: flex-end;
  color: #f5f5f5;
  max-width: 1300px;
  width: -moz-fit-content;
  width: fit-content;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  transform: translate(0, -50%);
  transition: all 1.5s;
  transition-timing-function: ease-in-out;
  top: 50%;
}
._TitleWithFixedImage-module__mainContainer__1kRx0 ._TitleWithFixedImage-module__innerHtmlContainer__1qMli h2 {
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  background-color: #003a78;
  padding: 10px 1rem 10px 4rem;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: -1px;
  font-size: 32pt;
  white-space: nowrap;
}
@media (max-width: 900px) {
  ._TitleWithFixedImage-module__mainContainer__1kRx0 ._TitleWithFixedImage-module__innerHtmlContainer__1qMli h2 {
    font-size: 25px;
  }
}
@media (max-width: 700px) {
  ._TitleWithFixedImage-module__mainContainer__1kRx0 ._TitleWithFixedImage-module__innerHtmlContainer__1qMli h2 {
    font-size: 22px;
  }
}
@media (max-width: 600px) {
  ._TitleWithFixedImage-module__mainContainer__1kRx0 ._TitleWithFixedImage-module__innerHtmlContainer__1qMli h2 {
    font-size: 20px;
  }
}
@media (max-width: 600px) {
  ._TitleWithFixedImage-module__mainContainer__1kRx0 ._TitleWithFixedImage-module__innerHtmlContainer__1qMli h2 {
    font-size: 18px;
  }
}
._TitleWithFixedImage-module__mainContainer__1kRx0 ._TitleWithFixedImage-module__innerHtmlContainer__1qMli p {
  padding: 10px 1rem 10px 4rem;
  background-color: #003a78;
  margin: 0;
  font-family: "Fraktion Mono";
  text-transform: uppercase;
  font-size: 16px;
}
._TitleWithFixedImage-module__mainContainer__1kRx0 ._TitleWithFixedImage-module__playButton__3nnCj {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #003a78;
  font-size: 50px;
  color: #f5f5f5;
  padding: 10px 25px;
  cursor: pointer;
}
._ProjectCard-module__mainContainer__208at {
  position: relative;
  display: flex;
  align-items: start;
  justify-content: start;
  border: 0.5px solid #003a78;
  width: 450px;
  height: 350px;
  opacity: 0;
  transform: translateY(50px);
  animation: _ProjectCard-module__fadeIn__2Djtn 0.8s forwards;
}
@media (max-width: 600px) {
  ._ProjectCard-module__mainContainer__208at {
    width: 100%;
  }
}
._ProjectCard-module__mainContainer__208at ._ProjectCard-module__progressInfo__2j4ky {
  position: absolute;
  top: 20px;
  left: 20px;
  color: #003a78;
  font-size: 14px;
  font-family: "Fraktion Mono";
  text-transform: uppercase;
  border: 1px solid #003a78;
  background-color: #f5f5f5;
  padding: 6px 12px;
}
._ProjectCard-module__mainContainer__208at img {
  width: 100%;
  height: 230px;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
}
._ProjectCard-module__mainContainer__208at ._ProjectCard-module__cardInfoBar__2cb3c {
  width: 100%;
  height: 120px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
}
._ProjectCard-module__mainContainer__208at ._ProjectCard-module__cardInfoBar__2cb3c p,
._ProjectCard-module__mainContainer__208at ._ProjectCard-module__cardInfoBar__2cb3c h3 {
  margin: 0;
  color: #003a78;
  text-transform: uppercase;
  font-size: 11pt;
}
._ProjectCard-module__mainContainer__208at ._ProjectCard-module__cardInfoBar__2cb3c p {
  font-size: 11pt;
  font-family: "Fraktion Mono";
  text-transform: uppercase;
}
._ProjectCard-module__mainContainer__208at ._ProjectCard-module__cardInfoBar__2cb3c ._ProjectCard-module__actionBar__1CxiG {
  display: flex;
  align-items: center;
}
._ProjectCard-module__mainContainer__208at ._ProjectCard-module__cardInfoBar__2cb3c ._ProjectCard-module__actionBar__1CxiG ._ProjectCard-module__iconContainer__1ltZE {
  margin-left: auto;
  display: flex;
  gap: 1rem;
}
._ProjectCard-module__mainContainer__208at ._ProjectCard-module__cardInfoBar__2cb3c ._ProjectCard-module__actionBar__1CxiG ._ProjectCard-module__iconContainer__1ltZE a {
  text-decoration: none;
  width: 50px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #003a78;
}
._ProjectCard-module__mainContainer__208at ._ProjectCard-module__cardInfoBar__2cb3c ._ProjectCard-module__actionBar__1CxiG ._ProjectCard-module__iconContainer__1ltZE a ._ProjectCard-module__icon__24o-Y {
  color: #003a78;
  font-size: 2rem;
  cursor: pointer;
}

/* Keyframes for the fade-in animation */
@keyframes _ProjectCard-module__fadeIn__2Djtn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
._Cta-module__outerBorder__1HqRS {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border: 1px solid #003a78;
  position: relative;
}
._Cta-module__outerBorder__1HqRS ._Cta-module__iconContainer__2Eoaq {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  font-size: 50px;
  color: #003a78;
  top: 50%;
  left: 50%;
  transform: rotate(90deg) translate(-50%, 50%);
  margin: 0;
}
._Cta-module__outerBorder__1HqRS ._Cta-module__iconContainer__2Eoaq p {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  text-align: center;
  margin: auto;
}
._Cta-module__outerBorder__1HqRS:hover {
  transform: rotate(-90deg);
  transition: transform 0.5s ease;
  cursor: pointer;
}
._TitleWithLine-module__titleSection__1MrN0 {
  display: flex;
  width: 100%;
  margin: 0px auto 20px auto;
  gap: 20px;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: #003a78;
}
@media (max-width: 981px) {
  ._TitleWithLine-module__titleSection__1MrN0 {
    justify-content: flex-start;
  }
}
._TitleWithLine-module__titleSection__1MrN0 h2 {
  width: -moz-fit-content;
  width: fit-content;
  white-space: nowrap;
}
@media (max-width: 981px) {
  ._TitleWithLine-module__titleSection__1MrN0 h2 {
    font-size: 20px;
  }
}
@media (max-width: 600px) {
  ._TitleWithLine-module__titleSection__1MrN0 h2 {
    font-size: 14px;
    white-space: unset;
  }
}
._TitleWithLine-module__titleSection__1MrN0 ._TitleWithLine-module__line__XV1jq {
  width: 100%;
  height: 2px;
  background-color: #003a78;
}
@media (max-width: 981px) {
  ._TitleWithLine-module__titleSection__1MrN0 ._TitleWithLine-module__line__XV1jq {
    display: none;
  }
}
._DownloadLineSkin-module__row__qYeDJ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border-bottom: 1px solid #003a78;
  width: 100%;
}
._DownloadLineSkin-module__row__qYeDJ:hover {
  background-color: #e3e3e3;
}

._DownloadLineSkin-module__documentTitle__3ZXc2 {
  color: #003a78;
  text-transform: uppercase;
}

._DownloadLineSkin-module__svg-container__32pC4 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh; /* Full height of the viewport */
}

svg {
  fill: #003a78;
  stroke: none;
  cursor: pointer;
  width: 25px;
  height: 25px;
}
._ViewLineSkin-module__row__3usmO {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border-bottom: 1px solid #003a78;
}
._ViewLineSkin-module__row__3usmO:hover {
  background-color: #e3e3e3;
}

._ViewLineSkin-module__documentTitle__2NBUk {
  color: #003a78;
}

._ViewLineSkin-module__button__3deYg {
  outline: none;
  border: 1px solid #003a78;
  color: white;
  background-color: #003a78;
  padding: 8px 16px;
  width: 150px;
  cursor: pointer;
  font-family: "Druk Wide Medium";
  font-size: 12px;
  text-transform: uppercase;
}
._ViewLineSkin-module__button__3deYg:hover {
  background-color: white;
  color: #003a78;
}
._ProjectsSlider-module__sliderContainer__3lyXE {
  position: relative;
  overflow: hidden;
  width: 100%;
}
._ProjectsSlider-module__sliderContainer__3lyXE ._ProjectsSlider-module__projectGrid__2beD- {
  display: flex;
  gap: 2vw;
  padding: 4rem 0;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
}
._ProjectsSlider-module__sliderContainer__3lyXE ._ProjectsSlider-module__prevButton__24o6S,
._ProjectsSlider-module__sliderContainer__3lyXE ._ProjectsSlider-module__nextButton__1a6RB {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  font-size: 150px;
  color: #003a78;
  padding: 0.5rem 1rem;
  cursor: pointer;
  z-index: 9999;
}
._ProjectsSlider-module__sliderContainer__3lyXE ._ProjectsSlider-module__prevButton__24o6S:disabled,
._ProjectsSlider-module__sliderContainer__3lyXE ._ProjectsSlider-module__nextButton__1a6RB:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
._ProjectsSlider-module__sliderContainer__3lyXE ._ProjectsSlider-module__prevButton__24o6S {
  left: 50px;
}
._ProjectsSlider-module__sliderContainer__3lyXE ._ProjectsSlider-module__nextButton__1a6RB {
  right: 50px;
}
._MapPointer-module__pointer__2CvUk {
  cursor: pointer;
  position: absolute;
  border: 2px solid #003a78;
  border-radius: 100px;
}

._MapPointer-module__label__18Dpe {
  position: absolute;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 8px;
  height: 20px;
  color: white;
  background-color: #003a78;
}

._MapPointer-module__circle__3ODBk {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #003a78;
  position: relative;
  top: 8px;
  left: 8px;
  transform: translate(-50%, -50%);
  animation: _MapPointer-module__growShrink__2Uni5 1s infinite alternate;
}

._MapPointer-module__home__3KegC {
  width: 25px !important;
  height: 25px !important;
  border-radius: 50%;
  color: #003a78;
  position: relative;
  top: 0px;
  left: 1px;
  transform: translate(-50%, -50%);
  animation: _MapPointer-module__growLessShrink__2mN0Z 1s infinite alternate;
}

@keyframes _MapPointer-module__growShrink__2Uni5 {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(2);
  }
}
@keyframes _MapPointer-module__growLessShrink__2mN0Z {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}
._index-module__buttonContainer__2SDdf {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 90px auto;
  text-align: center;
  white-space: nowrap;
}

._index-module__blueButton__nNDG_ {
  width: -moz-fit-content;
  width: fit-content;
  height: 50px;
  right: 0;
  bottom: 0;
  outline: none;
  color: #003a78;
  background-color: #003a78;
  padding: 8px 24px;
  border: none;
  cursor: pointer;
  font-family: "Druk Wide Medium";
  font-size: 12px !important;
  text-transform: uppercase;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}